import type {PropsWithChildren} from 'react'
import {createContext, useState} from 'react'
import type {
	GlobalState,
	TGlobalState,
} from '@/_new-code/models/global-state.ts'

type GlobalClientStateProps = PropsWithChildren<{
	initialGlobalState?: GlobalState
}>

export const GlobalContext = createContext<TGlobalState | null>(null)

export const GlobalClientState = ({
	children,
	initialGlobalState,
}: GlobalClientStateProps): JSX.Element => {
	const [globalState, setGlobalState] = useState<GlobalState | null>(
		initialGlobalState ?? null
	)

	return (
		<GlobalContext.Provider
			value={[globalState, setGlobalState] as TGlobalState}
		>
			{children}
		</GlobalContext.Provider>
	)
}
