import {useState, useEffect, Fragment} from 'react'
import type {QueryFunctionContext} from 'react-query'
import {useQuery, useQueryClient, useMutation} from 'react-query'
import {useRouter} from 'next/router'
import Image from 'next/future/image'
import type {Elements, IContentItem} from '@kontent-ai/delivery-sdk'
import {groupReminders} from '@/utils/YPAY/reminders'
import {convertDateToLocale} from '@/utils/YPAY/commonFunctions'
import type {
	PetFormData,
	SinglePetQueryKey,
} from '@/_new-code/services/pet-profiles/models'
import {fetchSinglePetProfile} from '@/_new-code/services/pet-profiles/client'
import {fetchPetReminders} from '@/_new-code/services/reminders/client'
import {Alert} from '@/_new-code/components/Alert'
import {Modal} from '@/_new-code/components/modal/modal'
import type {Pet} from '@/_new-code/models/consumer-engagement-models'
import {loadImageFromKontentAI} from '@/imageLoader'
import type {IconContentItem} from '@/_new-code/products/flexible-web-toolkit/blocks/icon'
import type {AddButtonIconContentItem} from '@/_new-code/products/your-pet-and-you/blocks/add-button-icon'
import type {
	Block,
	PetIconContentItem,
	Tersed,
} from '@/_new-code/services/kontent-ai/types'
import {updatePetProfile} from '@/_new-code/products/your-pet-and-you/mutations/update-pet-profile'
import type {ModulePetCreateFormContentItem} from '../../module-pet-create-form'
import {ModulePetCreateFormBlock} from '../../module-pet-create-form'
import type {PetIconFormContentItem} from '../../pet-icon-form'
import {PetIconFormBlock} from '../../pet-icon-form'
import type {PetDeleteModalContentItem} from '../pet-delete-modal'
import {PetDeleteModalBlock} from '../pet-delete-modal'

export type ModulePetProfileCardContentItem = IContentItem<{
	petSpecies: Elements.TextElement
	dateOfBirth: Elements.TextElement
	petWeight: Elements.TextElement
	petSex: Elements.TextElement
	petBreed: Elements.TextElement
	adoptionDay: Elements.TextElement
	petAcquisition: Elements.TextElement
	selectedClinic: Elements.TextElement
	noSelectedClinicText: Elements.TextElement
	selectedShelter: Elements.TextElement
	associatedReminders: Elements.TextElement
	editPetProfile: Elements.TextElement
	addReminder: Elements.TextElement
	yearText: Elements.TextElement
	yearsText: Elements.TextElement
	monthText: Elements.TextElement
	monthsText: Elements.TextElement
	addReminderButtonText: Elements.TextElement
	editPetProfileButtonText: Elements.TextElement
	deletePetButtonText: Elements.TextElement
	petForm: Elements.LinkedItemsElement<ModulePetCreateFormContentItem>
	petDeleteModal: Elements.LinkedItemsElement<PetDeleteModalContentItem>
	icon: Elements.LinkedItemsElement<IconContentItem>
	addButtonIcon: Elements.LinkedItemsElement<AddButtonIconContentItem>
	petIconForm: Elements.LinkedItemsElement<PetIconFormContentItem>
	editReminderIcon: Elements.LinkedItemsElement<IconContentItem>
	reminderModalUrl: Elements.TextElement
	doseDay: Elements.TextElement
	noReminders: Elements.TextElement
	noGotchaDayText: Elements.TextElement
	addPetIcon: Elements.LinkedItemsElement<AddButtonIconContentItem>
	petProfileHeading: Elements.TextElement
	addPet: Elements.TextElement
}>

export const ModulePetProfileCardBlock: Block<
	ModulePetProfileCardContentItem
> = ({
	block: {
		elements: {
			petSpecies,
			dateOfBirth,
			petWeight,
			petSex,
			petBreed,
			adoptionDay,
			petAcquisition: acquisitionType,
			associatedReminders: associatedRemindersText,
			selectedClinic,
			selectedShelter,
			editPetProfile,
			addReminder,
			yearText,
			yearsText,
			monthText,
			monthsText,
			deletePetButtonText,
			icon,
			addButtonIcon,
			petIconForm,
			addPet,
			addPetIcon,
			petProfileHeading,
			editReminderIcon,
			reminderModalUrl,
			doseDay,
			noReminders,
			noGotchaDayText,
			petDeleteModal,
			petForm,
		},
	},
	globalConfig,
	...context
}) => {
	const {
		defaultCatIcon: [defaultCatIcon],
		defaultDogIcon: [defaultDogIcon],
	} = globalConfig.elements
	const {locale: pageLocale = ''} = useRouter()

	const [showPetCreateForm, setShowPetCreateForm] = useState(false)
	const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] =
		useState(false)
	const [showModal, setShowModal] = useState(false)
	const [iconFormError, setIconFormError] = useState(false)

	const router = useRouter()
	const id = router.query.id?.toString()

	const {
		data,
		isLoading,
		isError: petApiError,
	} = useQuery({
		queryKey: ['petData', {id}],
		queryFn: (queryContext: QueryFunctionContext<SinglePetQueryKey>) =>
			fetchSinglePetProfile(queryContext, pageLocale),
		staleTime: Infinity,
		cacheTime: 15 * (60 * 1000),
	})

	const {data: remindersData, isError: reminderApiError} = useQuery({
		queryKey: ['remindersData', {id}],
		queryFn: () => fetchPetReminders(pageLocale),
		staleTime: Infinity,
		cacheTime: 15 * (60 * 1000),
	})

	const queryClient = useQueryClient()

	const getPetAge = (birthDate: string): string => {
		const newBirthDate = new Date(birthDate)
		const currentDate = new Date()

		const numberOfDays = Math.ceil(
			Math.abs(newBirthDate.valueOf() - currentDate.valueOf()) /
				(1000 * 60 * 60 * 24)
		)
		const years = Math.floor(numberOfDays / 365)
		const months = Math.floor((numberOfDays % 365) / 30)

		const petAge = `${years} ${
			years && years === 1 ? yearText : yearsText
		}, ${months} ${months && months === 1 ? monthText : monthsText}`

		return petAge
	}

	interface PetIcon {
		id: string
		species: string | undefined
		imageUrl: string | undefined
	}

	const mapIcon = (rawItem: Tersed<PetIconContentItem>): PetIcon => ({
		id: rawItem.system.id,
		species: rawItem.elements.species[0]?.codename,
		imageUrl: rawItem.elements.image[0]?.url,
	})

	const filterIconBySpecies = (petIcon: PetIcon): boolean =>
		petIcon.species?.[0]?.toLowerCase() === data?.species.toLowerCase()

	const petIcons = globalConfig.elements.petIcons
		.map(mapIcon)
		.filter(filterIconBySpecies)

	const defaultIcon =
		defaultCatIcon && defaultDogIcon
			? mapIcon(data?.species === 'dog' ? defaultDogIcon : defaultCatIcon)
			: null

	const [selectedPetIcon, setSelectedPetIcon] = useState(defaultIcon)

	useEffect(() => {
		if (!data) return

		const petIcon = petIcons.find((item) => item.id === data.imageReference)
		if (petIcon) setSelectedPetIcon(petIcon)
	}, [data, petIcons])

	const handleEditPetProfile = (): void => {
		setShowPetCreateForm(true)
	}

	const closeEditPetProfile = (): void => {
		setShowPetCreateForm(false)
	}

	const displayDeleteModal = (): void => {
		setShowDeleteConfirmationModal(true)
		window.scrollTo(0, 0)
	}

	const handleEditPetProfileImage = (): void => {
		setShowModal(true)
	}

	const {mutate, isLoading: isSubmittingData} = useMutation({
		mutationFn: (petData: PetFormData) =>
			updatePetProfile(petData, pageLocale),
		mutationKey: 'petProfile/update',
		onSuccess: () => queryClient.invalidateQueries(['petData', {id}]),
	})

	const mapPetResultToPetForm = (
		petId: string,
		imageReference: string,
		petData: Pet
	): PetFormData => ({
		id: petId,
		petName: petData.name,
		petSex: petData.gender,
		breed: petData.breed,
		petSpecies: petData.species,
		petDateOfBirth: petData.birthDate,
		petWeight: Number(petData.weight.value),
		petAcquisitionDate: petData.adoptionDate,
		petAcquisitionType: petData.acquisitionSource,
		petShelter: petData.acquisitionDetail?.name ?? '',
		vetClinic: petData.vetClinic?.name ?? '',
		imageReference,
	})

	const updateImage = (imageReference: string): void => {
		setIconFormError(false)
		if (!data) {
			return
		}

		try {
			mutate(mapPetResultToPetForm(data.id, imageReference, data))
			setShowModal(false)
		} catch {
			setIconFormError(true)
		}
	}

	const handleAddPet = (): void => {
		void router.push(
			`/${pageLocale}${addPetIcon[0]?.elements.ctaButton[0]?.elements.url}`
		)
	}

	const handleAddReminder = (): void => {
		void router.push(
			{
				pathname: `/${pageLocale}${reminderModalUrl}`,
				query: {
					id,
					reminder: id,
				},
			},
			undefined,
			{shallow: true}
		)
	}

	const reminders = remindersData?.filter((i) =>
		Array(i).find((r) => r.pet?.id === id)
	)

	const groupedMedicationsArray = groupReminders(reminders || [])

	if (petApiError || reminderApiError) {
		return (
			<div className="container-wide relative mt-8 flex flex-col sm:items-start">
				<div className="ml-10 flex w-5/6 flex-col justify-between sm:ml-4 md:w-large-card-width">
					<h1 className="font-semibold">{petProfileHeading}</h1>
					<div className="mt-8">
						<Alert
							message={globalConfig.elements.petApiError}
							type="error"
						/>
					</div>
				</div>
			</div>
		)
	}

	return (
		<>
			<div className="container-wide relative mt-8 flex flex-col sm:items-start">
				<div className="ml-10 flex w-5/6 items-center justify-between sm:ml-4 md:w-large-card-width">
					<h1 className="font-semibold">{petProfileHeading}</h1>
					<div className="mr-1 flex items-center sm:mr-4">
						<p className="mr-2 text-xs text-charcoal">{addPet}</p>
						{}
						<Image
							alt={
								addPetIcon[0]?.elements.icon[0]?.elements
									.icon[0]?.description ?? ''
							}
							className="cursor-pointer"
							height={32}
							loader={loadImageFromKontentAI}
							onClick={handleAddPet}
							src={
								addPetIcon[0]?.elements.icon[0]?.elements
									.icon[0]?.url ?? ''
							}
							width={32}
						/>
					</div>
				</div>
				<div className="h-small-card-height sm:h-large-card-height relative mx-auto my-0 mb-20 mt-10 flex w-5/6 flex-col rounded border border-light-grey px-4 pb-5 pt-6 sm:mx-2 sm:pb-20 sm:pl-20 sm:pr-20 md:w-large-card-width">
					{isLoading ? (
						<>
							<div className="mb-6 h-48 w-48 animate-pulse rounded-full bg-gray-200" />
							<div className="mb-6 h-14 w-full animate-pulse rounded bg-gray-200" />
							<div className="mb-6 flex flex-row gap-8 md:gap-24">
								<div className="h-6 w-1/3 animate-pulse rounded bg-gray-200" />
								<div className="h-6 w-1/3 animate-pulse rounded bg-gray-200" />
							</div>
							<div className="mb-6 flex flex-row gap-8 md:gap-24">
								<div className="h-6 w-1/3 animate-pulse rounded bg-gray-200" />
								<div className="h-6 w-1/3 animate-pulse rounded bg-gray-200" />
							</div>
							<div className="mb-6 flex flex-row gap-8 md:gap-24">
								<div className="h-6 w-1/3 animate-pulse rounded bg-gray-200" />
								<div className="h-6 w-1/3 animate-pulse rounded bg-gray-200" />
							</div>
							<div className="mb-6 h-6 w-3/4 animate-pulse rounded bg-gray-200" />
							<div className="mb-6 h-6 w-3/4 animate-pulse rounded bg-gray-200" />
							<div className="mb-6 h-14 w-full animate-pulse rounded bg-gray-200" />
							<div className="mb-6 h-10 w-full animate-pulse rounded bg-gray-200" />
							<div className="mb-6 h-10 w-full animate-pulse rounded bg-gray-200" />
							<div className="m-auto mb-4 h-6 w-24 animate-pulse rounded bg-gray-200" />
						</>
					) : null}
					{!isLoading && data ? (
						<>
							<div className="flex flex-col items-center sm:items-start sm:justify-start">
								<div className="mb-4 flex w-full items-center justify-center sm:justify-start">
									<div className="ml-6 flex sm:ml-0">
										{selectedPetIcon?.imageUrl ||
										defaultIcon?.imageUrl ? (
											<Image
												alt={defaultIcon?.species}
												className="h-48 w-48 rounded-full border-4 border-pet-green"
												height={192}
												loader={loadImageFromKontentAI}
												// @ts-expect-error This isn't undefined, see the conditional
												src={
													selectedPetIcon?.imageUrl ??
													defaultIcon?.imageUrl
												}
												width={192}
											/>
										) : null}
										<div className="ml-2 flex cursor-pointer items-center justify-center">
											{icon[0]?.elements.icon[0] ? (
												<Image
													alt={
														icon[0].elements.icon[0]
															.description ?? ''
													}
													height={32}
													loader={
														loadImageFromKontentAI
													}
													onClick={
														handleEditPetProfileImage
													}
													src={
														icon[0].elements.icon[0]
															.url
													}
													width={32}
												/>
											) : null}
										</div>
									</div>
								</div>
								<div className="line-medium relative flex w-full flex-col items-center sm:flex-row">
									<div className="flex flex-col leading-5 text-pet-primary sm:items-start">
										<h1 className="text-center text-2xl font-bold uppercase sm:text-start">
											{data.name}
										</h1>
										<h3 className="text-center text-sm font-normal sm:text-start">
											{data.birthDate
												? getPetAge(data.birthDate)
												: null}
										</h3>
									</div>
									<div className="absolute -right-6 top-4 mr-7 sm:-right-3">
										<div className="cursor-pointer">
											{icon[0]?.elements.icon[0] ? (
												<Image
													alt={
														icon[0].elements.icon[0]
															.description ?? ''
													}
													height={32}
													loader={
														loadImageFromKontentAI
													}
													onClick={
														handleEditPetProfile
													}
													src={
														icon[0].elements.icon[0]
															.url
													}
													width={32}
												/>
											) : null}
										</div>
									</div>
								</div>
							</div>
							<hr className="border-1 border-midGrey mt-5" />
							<div className="leading-medium mt-4 flex w-full">
								<div className="w-1/2">
									<div className="text-sm italic text-charcoal">
										<p>{dateOfBirth}</p>
									</div>
									<div className="text-base text-pet-primary">
										<p>
											{convertDateToLocale(
												data.birthDate
											)}
										</p>
									</div>
								</div>
								<div className="w-1/2">
									<div className="text-sm italic text-charcoal">
										<p>{adoptionDay}</p>
									</div>
									<div className="text-base text-pet-primary">
										<p>
											{data.adoptionDate
												? convertDateToLocale(
														data.adoptionDate
													)
												: noGotchaDayText}
										</p>
									</div>
								</div>
							</div>
							<div className="leading-medium mt-4 flex w-full">
								<div className="w-1/2">
									<div className="text-sm italic text-charcoal">
										<p>{petSpecies}</p>
									</div>
									<div className="text-base text-pet-primary">
										<p className="first-letter:capitalize">
											{data.species}
										</p>
									</div>
								</div>
								<div className="w-1/2">
									<div className="text-sm italic text-charcoal">
										<p>{petSex}</p>
									</div>
									<div className="text-base text-pet-primary">
										<p className="first-letter:capitalize">
											{data.gender}
										</p>
									</div>
								</div>
							</div>
							<div className="leading-medium mt-4 flex w-full">
								<div className="w-1/2">
									<div className="text-sm italic text-charcoal">
										<p>{petWeight}</p>
									</div>
									<div className="text-base text-pet-primary">
										<p>{data.weight.value} lbs</p>
									</div>
								</div>
								<div className="w-1/2">
									<div className="text-sm italic text-charcoal">
										<p>{petBreed}</p>
									</div>
									<div className="text-base text-pet-primary">
										<p>{data.breed}</p>
									</div>
								</div>
							</div>
							<div className="leading-medium mt-4 flex w-full flex-col">
								<div className="">
									<div className="text-sm italic text-charcoal">
										<p>{acquisitionType}</p>
									</div>
									<div className="text-base text-pet-primary">
										<p>{data.acquisitionSource}</p>
									</div>
								</div>
							</div>
							<div className="leading-medium mt-4 flex w-full">
								<div className="w-1/2">
									<div className="text-sm italic text-charcoal">
										<p>{selectedClinic}</p>
									</div>
									<div className="text-base text-pet-primary">
										<p>{data.vetClinic?.name}</p>
									</div>
								</div>
								<div className="w-1/2">
									<div className="text-sm italic text-charcoal">
										<p>{selectedShelter}</p>
									</div>
									<div className="text-base text-pet-primary">
										<p>{data.acquisitionDetail?.name}</p>
									</div>
								</div>
							</div>
							<div className="mt-8 flex w-full pb-2">
								<div className="flex w-2/3 items-center justify-start text-sm font-bold leading-5 text-pet-primary">
									<p>
										{data.name}
										{associatedRemindersText}
									</p>
								</div>
								<div className="leading-medium mr-0 flex w-1/3 cursor-pointer items-center justify-end sm:mr-5">
									<p className="mr-2 text-xs text-charcoal">
										{addReminder}
									</p>
									{addButtonIcon[0]?.elements.icon[0]
										?.elements.icon[0] ? (
										<Image
											alt={
												addButtonIcon[0].elements
													.icon[0].elements.icon[0]
													.description ?? ''
											}
											height={32}
											loader={loadImageFromKontentAI}
											onClick={handleAddReminder}
											src={
												addButtonIcon[0].elements
													.icon[0].elements.icon[0]
													.url
											}
											width={32}
										/>
									) : null}
								</div>
							</div>
							<hr className="border-1 border-midGrey mt-4" />
							<div className="w-full items-center py-4">
								{groupedMedicationsArray.length > 0 ? (
									groupedMedicationsArray.map((rem) => (
										<Fragment key={rem.id}>
											<div className="flex w-full justify-between">
												<div className="flex flex-col">
													<div className="">
														<div>
															<p>
																{
																	rem
																		.marketingBrand
																		?.name
																}
															</p>
														</div>
														<div className="flex w-full flex-row items-center">
															<p className="text-sm italic">
																{doseDay}
															</p>
															<span className="ml-1 text-sm text-[#333]">
																{
																	rem
																		.recurringDates?.[0]
																}
															</span>
														</div>
													</div>
												</div>
												<div className="mr-0 flex w-20 cursor-pointer items-center justify-end sm:mr-6">
													{editReminderIcon[0]
														?.elements.icon[0] ? (
														<Image
															alt={
																editReminderIcon[0]
																	.elements
																	.icon[0]
																	.description ??
																''
															}
															height={32}
															loader={
																loadImageFromKontentAI
															}
															onClick={() => {
																void router.push(
																	{
																		pathname: `/${pageLocale}${reminderModalUrl}`,
																		query: {
																			id,
																			edit: true,
																			reminder:
																				rem.id,
																		},
																	},
																	undefined,
																	{
																		shallow:
																			true,
																	}
																)
																window.scrollTo(
																	0,
																	0
																)
															}}
															src={
																editReminderIcon[0]
																	.elements
																	.icon[0].url
															}
															width={32}
														/>
													) : null}
												</div>
											</div>
											<hr className="border-1 border-midGrey my-2" />
										</Fragment>
									))
								) : (
									<div className="flex items-center">
										<p>{noReminders}</p>
									</div>
								)}
							</div>
							<div className="mt-4 flex justify-center">
								{/* eslint-disable-next-line jsx-a11y/anchor-is-valid, jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions -- TODO: a11y fixes */}
								<a
									className="cursor-pointer text-green-600 underline"
									onClick={displayDeleteModal}
									type="button"
								>
									{deletePetButtonText}
								</a>
							</div>

							{showDeleteConfirmationModal &&
							petDeleteModal[0] ? (
								<PetDeleteModalBlock
									block={petDeleteModal[0]}
									globalConfig={globalConfig}
									hideModal={() => {
										setShowDeleteConfirmationModal(false)
									}}
									petData={data}
									showModal={showDeleteConfirmationModal}
									{...context}
								/>
							) : null}
						</>
					) : null}
				</div>
			</div>
			{showPetCreateForm && data ? (
				<Modal
					onClose={() => {
						setShowPetCreateForm(false)
					}}
					size="lg"
					title={editPetProfile}
				>
					{petForm[0] ? (
						<ModulePetCreateFormBlock
							block={petForm[0]}
							data={data}
							globalConfig={globalConfig}
							hideHeading
							isEdit
							onSubmit={() => {
								closeEditPetProfile()
							}}
							{...context}
						/>
					) : null}
				</Modal>
			) : null}
			{(showModal || isSubmittingData) && petIconForm[0] ? (
				<Modal
					onClose={() => {
						setShowModal(false)
					}}
					size="lg"
				>
					{selectedPetIcon || defaultIcon ? (
						<PetIconFormBlock
							block={petIconForm[0]}
							error={
								iconFormError ? (
									<Alert
										message={
											globalConfig.elements.petApiError
										}
										type="error"
									/>
								) : null
							}
							// @ts-expect-error -- Typescript is being odd - || statement checks that this is the case
							icon={selectedPetIcon ?? defaultIcon}
							icons={petIcons}
							isEdit
							isLoading={isSubmittingData}
							onSubmit={updateImage}
							petName={data?.name ?? ''}
						/>
					) : null}
				</Modal>
			) : null}
		</>
	)
}
