import {BusinessMapWithSearchFilterAndInfoWindow as GoogleBusinessMap} from '@elanco/component-library-v2'
import {useQuery} from 'react-query'
import type {Elements, IContentItem} from '@kontent-ai/delivery-sdk'
import {useRouter} from 'next/router'
import {useState} from 'react'
import {AxiosError} from 'axios'
import {useEffect} from 'react'
import {useFetchBusinessMapMarkers} from '@/_new-code/services/business-map-markers/client'
import type {Location, Marker} from '@/_new-code/models/page-props'
import {env} from '@/utils/env/client.mjs'
import type {IconContentItem} from '@/_new-code/products/flexible-web-toolkit/blocks/icon'
import type {Block, Tersed} from '@/_new-code/services/kontent-ai/types'
import {BUSINESS_TYPE_MAP} from '@/_new-code/products/find-a-business/constants'
import {LoadingSpinner} from '@/_new-code/products/flexible-web-toolkit/components/loading-spinner'
import {getColorHex} from '@/_new-code/utilities/hex-colors'
import {logEvent, logError} from '@/services/client-logger'

const stringifyLocation = (location: Location): string => {
	let address = ''
	address =
		address +
		(location.address ? location.address : '') +
		(location.address ? '\n' : '') +
		(location.address2 ? location.address2 : '') +
		(location.address2 ? '\n' : '') +
		(location.city ? location.city : '') +
		(location.city ? '\n' : '') +
		(location.postalCode ? location.postalCode : '') +
		(location.postalCode ? '\n' : '') +
		(location.state ? location.state : '') +
		(location.state ? '\n' : '') +
		(location.countryCode ? location.countryCode : '') +
		(location.countryCode ? '\n' : '')
	return address
}

/**
 * @returns A mapping of typeId codename to the image URL for that marker
 */
const getImagesFromBusinessTypes = (
	types: Tersed<BusinessTypeItemContentItem>[]
): Record<string, string> => {
	const data: Record<string, string> = {}
	types.forEach((businessType) => {
		const typeId = businessType.elements.typeId[0]
		const icons = businessType.elements.icons
		if (typeId) {
			if (icons.length > 0) {
				const icon = icons[0]
				const image = icon?.elements.icon[0]

				if (image) data[typeId.name] = image.url
			} else {
				data[typeId.name] = ''
			}
		}
	})
	return data
}

const COUNTRIES_MAP = {
	afghanistan: 'AF',
	åland_islands: 'AX',
	albania: 'AL',
	algeria: 'DZ',
	american_samoa: 'AS',
	andorra: 'AD',
	angola: 'AO',
	anguilla: 'AI',
	antarctica: 'AQ',
	antigua_and_barbuda: 'AG',
	argentina: 'AR',
	armenia: 'AM',
	aruba: 'AW',
	australia: 'AU',
	austria: 'AT',
	azerbaijan: 'AZ',
	bahrain: 'BH',
	bahamas: 'BS',
	bangladesh: 'BD',
	barbados: 'BB',
	belarus: 'BY',
	belgium: 'BE',
	belize: 'BZ',
	benin: 'BJ',
	bermuda: 'BM',
	bhutan: 'BT',
	bolivia: 'BO',
	bonaire: 'BES',
	bosnia_and_herzegovina: 'BA',
	botswana: 'BW',
	bouvet_island: 'BV',
	brazil: 'BR',
	british_indian_ocean_territory: 'IO',
	brunei_darussalam: 'BN',
	bulgaria: 'BG',
	burkina_faso: 'BF',
	burundi: 'BI',
	cambodia: 'KH',
	cameroon: 'CM',
	canada: 'CA',
	cape_verde: 'CV',
	cayman_islands: 'KY',
	central_african_republic: 'CF',
	chad: 'TD',
	chile: 'CL',
	china: 'CN',
	christmas_island: 'CX',
	colombia: 'CO',
	comoros: 'KM',
	congo: 'CG',
	republic_of_congo: 'CD',
	cook_islands: 'CK',
	costa_rica: 'CR',
	"côte_d'ivoire": 'CI',
	croatia: 'HR',
	cuba: 'CU',
	curaçao: 'CW',
	cyprus: 'CY',
	czech_republic: 'CZ',
	denmark: 'DK',
	djibouti: 'DJ',
	dominica: 'DM',
	dominican_republic: 'DO',
	ecuador: 'EC',
	egypt: 'EG',
	el_salvador: 'SV',
	equatorial_guinea: 'GQ',
	eritrea: 'ER',
	estonia: 'EE',
	ethiopia: 'ET',
	falkland_islands: 'FK',
	faroe_islands: 'FO',
	fiji: 'FJ',
	finland: 'FI',
	france: 'FR',
	french_guiana: 'GF',
	french_polynesia: 'PF',
	french_southern_territories: 'TF',
	gabon: 'GA',
	gambia: 'GM',
	georgia: 'GE',
	germany: 'DE',
	ghana: 'GH',
	gibraltar: 'GI',
	greece: 'GR',
	greenland: 'GL',
	grenada: 'GD',
	guadeloupe: 'GP',
	guam: 'GU',
	guatemala: 'GT',
	guernsey: 'GG',
	guinea: 'GN',
	guinea_bissau: 'GW',
	guyana: 'GY',
	haiti: 'HT',
	heard_island_and_mcdonald_islands: 'HM',
	holy_see: 'VA',
	honduras: 'HN',
	hong_kong: 'HK',
	hungary: 'HU',
	iceland: 'IS',
	india: 'IN',
	indonesia: 'ID',
	iran: 'IR',
	iraq: 'IQ',
	ireland: 'IE',
	isle_of_man: 'IM',
	israel: 'IL',
	italy: 'IT',
	jamaica: 'JM',
	japan: 'JP',
	jersey: 'JE',
	jordan: 'JO',
	kazakhstan: 'KZ',
	kenya: 'KE',
	kiribati: 'KI',
	north_korea: 'KP',
	south_korea: 'KR',
	kuwait: 'KW',
	kyrgyzstan: 'KG',
	lao: 'LA',
	latvia: 'LV',
	lebanon: 'LB',
	lesotho: 'LS',
	liberia: 'LR',
	libya: 'LY',
	liechtenstein: 'LI',
	lithuania: 'LT',
	luxembourg: 'LU',
	macao: 'MO',
	macedonia: '',
	madagascar: 'MG',
	malawi: 'MW',
	malaysia: 'MY',
	maldives: 'MV',
	mali: 'ML',
	malta: 'MT',
	marshall_islands: 'MH',
	martinique: 'MQ',
	mauritania: 'MR',
	mauritius: 'MU',
	mayotte: 'YT',
	mexico: 'MX',
	micronesia: 'FM',
	moldova: 'MD',
	monaco: 'MC',
	mongolia: 'MN',
	montenegro: 'ME',
	montserrat: 'MS',
	morocco: 'MA',
	mozambique: 'MZ',
	myanmar: 'MM',
	namibia: 'NA',
	nauru: 'NR',
	nepal: 'NP',
	netherlands: 'NL',
	new_caledonia: 'NC',
	new_zealand: 'NZ',
	nicaragua: 'NI',
	niger: 'NE',
	nigeria: 'NG',
	niue: 'NU',
	norfolk_island: 'NF',
	northern_mariana_islands: 'MP',
	norway: 'NO',
	oman: 'OM',
	pakistan: 'PK',
	palau: 'PW',
	palestine: 'PS',
	panama: 'PA',
	papua_new_guinea: 'PG',
	paraguay: 'PY',
	peru: 'PE',
	philippines: 'PH',
	pitcairn: 'PN',
	poland: 'PL',
	portugal: 'PT',
	puerto_rico: 'PR',
	qatar: 'QA',
	réunion: 'RE',
	romania: 'RO',
	russian_federation: 'RU',
	rwanda: 'RW',
	saint_barthélemy: 'BL',
	saint_helena_ascension_and_tristan_da_cunha: 'SH',
	saint_kitts_and_nevis: 'KN',
	saint_lucia: 'LC',
	saint_martin: 'MF',
	saint_pierre_and_miquelon: 'PM',
	saint_vincent_and_the_grenadines: 'VC',
	samoa: 'WS',
	san_marino: 'SM',
	sao_tome_and_principe: 'ST',
	saudi_arabia: 'SA',
	senegal: 'SN',
	serbia: 'RS',
	seychelles: 'SC',
	sierra_leone: 'SL',
	singapore: 'SG',
	sint_maarten: 'MF',
	slovakia: 'SK',
	slovenia: 'SI',
	solomon_islands: 'SB',
	somalia: 'SO',
	south_africa: 'ZA',
	south_georgia_and_the_south_sandwich_islands: 'GS',
	south_sudan: 'SS',
	spain: 'ES',
	sri_lanka: 'LK',
	sudan: 'SD',
	suriname: 'SR',
	svalbard_and_jan_mayen: 'SJ',
	swaziland: '',
	sweden: 'SE',
	switzerland: 'CH',
	syrian_arab_republic: 'SY',
	taiwan: 'TW',
	tajikistan: 'TJ',
	tanzania: 'TZ',
	thailand: 'TH',
	timor_leste: 'TL',
	togo: 'TG',
	tokelau: 'TK',
	tonga: 'TO',
	trinidad_and_tobago: 'TT',
	tunisia: 'TN',
	turkey: 'TR',
	turkmenistan: 'TM',
	turks_and_caicos_islands: 'TC',
	tuvalu: 'TV',
	uganda: 'UG',
	ukraine: 'UA',
	united_arab_emirates: 'AE',
	united_kingdom: 'GB',
	united_states: 'US',
	united_states_minor_outlying_islands: 'UM',
	uruguay: 'UY',
	uzbekistan: 'UZ',
	vanuatu: 'VU',
	venezuela: 'VE',
	vietnam: 'VN',
	virgin_islands_british: 'VG',
	virgin_islands_us: 'VI',
	wallis_and_futuna: 'WF',
	western_sahara: 'EH',
	yemen: 'YE',
	zambia: 'ZM',
	zimbabwe: 'ZW',
}

interface LocationState {
	permission: 'prompt' | 'denied' | 'granted'
	enableSearch: boolean
}

type BusinessTypeItemContentItem = IContentItem<{
	displayName: Elements.TextElement
	typeId: Elements.MultipleChoiceElement
	preselectedFilter: Elements.MultipleChoiceElement
	icons: Elements.LinkedItemsElement<IconContentItem>
}>

type ProductTagContentItem = IContentItem<{
	displayName: Elements.TextElement
	palId: Elements.TextElement
	preselectedFilter: Elements.MultipleChoiceElement
}>

interface FilterState {
	businessTypes: [
		{
			display_name: string
			preselected_filter: boolean
			type_id: {
				choice: string | undefined
			}
		},
	]
	tags: [
		{
			preselected_filter: {
				boolean: boolean
			}
			pal_id: string
			display_name: string
		},
	]
}

export type FindABusinessContentItem = IContentItem<{
	mapTitle: Elements.TextElement
	mapDescription: Elements.TextElement
	northBoundPoint: Elements.NumberElement
	eastBoundPoint: Elements.NumberElement
	southBoundPoint: Elements.NumberElement
	westBoundPoint: Elements.NumberElement
	radius: Elements.NumberElement
	label: Elements.TextElement
	placeholderText: Elements.TextElement
	locationText: Elements.TextElement
	allowedCountries: Elements.MultipleChoiceElement
	businessFilterTitle: Elements.TextElement
	businessType: Elements.LinkedItemsElement<BusinessTypeItemContentItem>
	tagsFilterTitle: Elements.TextElement
	tags: Elements.LinkedItemsElement<ProductTagContentItem>
	apiError: Elements.TextElement
	directionsText: Elements.TextElement
	filterButtonText: Elements.TextElement
	filterResetText: Elements.TextElement
	enterLocationPrompt: Elements.RichTextElement
	areaAccentColour: Elements.MultipleChoiceElement
	areaOpacity: Elements.NumberElement
}>

export const FindABusinessBlock: Block<FindABusinessContentItem> = ({
	block,
}) => {
	const {query} = useRouter()

	const radius = block.elements.radius ?? 50
	const businessTypeIds: string[] = []
	let filteredTags = block.elements.tags
	let filteredBusinessTypes = block.elements.businessType

	const businessesType = filteredBusinessTypes.map((bT) => ({
		display_name: bT.elements.displayName,
		preselected_filter:
			bT.elements.preselectedFilter[0]?.codename === 'yes',
		type_id: {
			choice: bT.elements.typeId[0]?.codename,
		},
	}))

	const tagsId = filteredTags.map((tag) => ({
		preselected_filter: {
			boolean: tag.elements.preselectedFilter[0]?.codename === 'yes',
		},
		pal_id: tag.elements.palId,
		display_name: tag.elements.displayName,
	}))

	const businessType = block.elements.businessType
		.map<Tersed<BusinessTypeItemContentItem> | null>((type) => {
			const typeId = type.elements.typeId[0]?.name
			if (!typeId) return null

			businessTypeIds.push(typeId)

			const mapped = BUSINESS_TYPE_MAP.get(typeId)
			if (mapped)
				return {
					...type,
					elements: {
						...type.elements,
						typeId: [
							{
								codename: mapped,
								name: type.elements.typeId[0]?.name ?? '',
							},
						],
					},
				}

			return type
		})
		.filter(Boolean)

	const [locationState, setLocationState] = useState<LocationState>({
		permission: 'prompt',
		enableSearch: false,
	})
	const enterLocationPromptValue = block.elements.enterLocationPrompt.value

	const defaultPromptMessage =
		'<p>Please enter your location in the search box to see clinics near you</p>'

	const promptContent =
		enterLocationPromptValue && enterLocationPromptValue !== '<p><br></p>'
			? enterLocationPromptValue
			: defaultPromptMessage

	const enterLocationPromptContent = enterLocationPromptValue
		? promptContent
		: ''

	const finalLocationPromptValue =
		locationState.permission !== 'granted' ? enterLocationPromptContent : ''
	const [searchLocationLatLong, setSearchLocationLatLong] = useState({
		searchName: '',
		lat: 0,
		lng: 0,
	})

	const [filterState, setFilterState] = useState({
		businessTypes: businessesType,
		tags: tagsId,
	})
	const handleFilterUpdate = (newState: FilterState): void => {
		setFilterState(newState)
	}

	const typeIdsString = businessTypeIds.join()
	const tagIdsString = block.elements.tags
		.filter((tag) => tag.elements.palId && tag.elements.palId.length > 0)
		.map((tag) => tag.elements.palId)
		.join()

	const getCountryCodes = (): string[] => {
		return block.elements.allowedCountries.map(
			(country) =>
				COUNTRIES_MAP[country.codename as keyof typeof COUNTRIES_MAP]
		)
	}

	const lat = searchLocationLatLong.lat
	const lon = searchLocationLatLong.lng
	useEffect(() => {
		logEvent({
			name: 'FIND_A_BUSINESS_CONFIGURED',
			properties: {
				title: block.elements.mapTitle,
				businessType,
				kontentCodename: block.system.codename,
				allowedCountries: block.elements.allowedCountries.map(
					(country) =>
						COUNTRIES_MAP[
							country.codename as keyof typeof COUNTRIES_MAP
						]
				),
			},
		})
	}, [
		block.elements.allowedCountries,
		block.elements.mapTitle,
		block.system.codename,
		businessType,
	])

	const {
		data: mapMarkers,
		error: mapError,
		isFetching,
	} = useQuery(
		[
			'businessMap',
			{
				tagIdsString,
				typeIdsString,
				lat,
				lon,
				radius,
			},
		],
		useFetchBusinessMapMarkers,
		{
			refetchOnWindowFocus: false,
			cacheTime: 15 * (60 * 1000),
			enabled: locationState.enableSearch,
		}
	)

	// eslint-disable-next-line @typescript-eslint/explicit-function-return-type -- implicit is fine here
	const convertBasicMarkersIntoPlainMarkers = (basicMarkers: Marker[]) => {
		const codenamesToMarkerImages = getImagesFromBusinessTypes(businessType)
		const filteredItems = basicMarkers.filter(
			(item) =>
				item.location?.latitude &&
				item.location.longitude &&
				item.location.latitude > -90.0 &&
				item.location.latitude < 90.0 &&
				item.location.longitude > -180.0 &&
				item.location.longitude < 180.0
		)
		const allMarkers = filteredItems.map((basicMarker) => {
			const {
				location,
				accountClassification,
				accountId,
				accountPhone,
				tags: localTags,
				websiteUrl,
				name,
			} = basicMarker
			const address = location ? stringifyLocation(location) : ''
			let imageURL = ''

			if (accountClassification) {
				const markerImage =
					codenamesToMarkerImages[accountClassification]
				if (markerImage) {
					imageURL = markerImage
				}
			}

			return {
				accountClassification,
				accountId,
				accountPhone,
				tags: localTags,
				lat: location?.latitude,
				lng: location?.longitude,
				address,
				websiteUrl,
				name,
				image: imageURL,
			}
		})
		return allMarkers
	}

	let mappedMarkers: Marker[] = []
	if (mapMarkers && mapMarkers.length > 0) {
		mappedMarkers = convertBasicMarkersIntoPlainMarkers(mapMarkers)
	}

	if (mapError) {
		logError(
			new Error(
				`Error occurred in Find-a-business api, ${
					mapError instanceof AxiosError ? mapError.message : ''
				}`
			)
		)
		return (
			<div data-kontent-element-codename="api_error">
				<h1>{block.elements.apiError}</h1>;
			</div>
		)
	}

	const productFilter = query.filter?.toString()
	if (productFilter) {
		filteredTags = block.elements.tags.map((tag) => ({
			...tag,
			preselected_filter:
				tag.elements.displayName === productFilter
					? {boolean: true, choice: 'yes'}
					: {boolean: false, choice: 'no'},
		}))
	}
	const businessTypeFilter = query.businessType?.toString()
	if (businessTypeFilter) {
		filteredBusinessTypes = block.elements.businessType.map((bt) => ({
			...bt,
			preselected_filter:
				bt.elements.displayName === businessTypeFilter
					? {boolean: true, choice: 'yes'}
					: {boolean: false, choice: 'no'},
		}))
	}

	return (
		<div data-kontent-element-codename="find_a_business">
			{isFetching ? (
				<div className="flex h-[800px] w-full animate-pulse items-center justify-center rounded bg-elanco-blue bg-opacity-20 p-5">
					<LoadingSpinner theme="blue" />
				</div>
			) : null}
			<div style={{display: isFetching ? 'none' : 'block'}}>
				<GoogleBusinessMap
					apiKey={env.NEXT_PUBLIC_MAP_API_KEY}
					businessFilterTitle={block.elements.businessFilterTitle}
					businessTypes={businessesType}
					countries={getCountryCodes()}
					currentLocationText={block.elements.locationText}
					defaultEastBound={block.elements.eastBoundPoint}
					defaultNorthBound={block.elements.northBoundPoint}
					defaultSouthBound={block.elements.southBoundPoint}
					defaultWestBound={block.elements.westBoundPoint}
					description={block.elements.mapDescription}
					directionsText={block.elements.directionsText}
					enterLocationPrompt={finalLocationPromptValue}
					filterButtonText={block.elements.filterButtonText}
					filterResetText={block.elements.filterResetText}
					filterState={filterState}
					handleFilterUpdate={handleFilterUpdate}
					label={block.elements.label}
					markers={mappedMarkers}
					placeholderText={block.elements.placeholderText}
					radius={radius}
					searchAreaAccentColour={getColorHex(
						block.elements.areaAccentColour[0]?.codename ?? 'white'
					)}
					searchAreaOpacity={block.elements.areaOpacity}
					searchLocationLatLong={searchLocationLatLong}
					setLocationState={setLocationState}
					setSearchLocationLatLong={setSearchLocationLatLong}
					tags={tagsId}
					tagsFilterTitle={block.elements.tagsFilterTitle}
					title={block.elements.mapTitle}
				/>
			</div>
		</div>
	)
}
