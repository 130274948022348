import {useState} from 'react'
import {Button} from '@elanco/component-library-v2'
import {useQuery} from 'react-query'
import type {Elements, IContentItem} from '@kontent-ai/delivery-sdk'
import {useRouter} from 'next/router'
import {fetchPetProfiles} from '@/_new-code/services/pet-profiles/client'
import type {Reminder} from '@/_new-code/services/reminders/models'
import {fetchPetReminders} from '@/_new-code/services/reminders/client'
import {Alert} from '@/_new-code/components/Alert'
import type {Block} from '@/_new-code/services/kontent-ai/types'
import type {Pet} from '@/_new-code/models/consumer-engagement-models'
import type {PetProfileCardSnippetContentItem} from '../pet-profile-card'
import {PetProfileCardSnippetBlock} from '../pet-profile-card'
import type {ModuleCreatePetCtaBlockContentItem} from '../create-pet-cta-card'
import {ModuleCreatePetCtaBlockBlock} from '../create-pet-cta-card'

export type ModuleDashboardPetCardsContentItem = IContentItem<{
	createPetCtaCardParent: Elements.LinkedItemsElement<ModuleCreatePetCtaBlockContentItem>
	profilePetCardSnippetParent: Elements.LinkedItemsElement<PetProfileCardSnippetContentItem>
	viewMorePetsText: Elements.TextElement
}>

export const ModuleDashboardPetCardsBlock: Block<
	ModuleDashboardPetCardsContentItem
> = ({
	block: {
		elements: {
			createPetCtaCardParent,
			profilePetCardSnippetParent,
			viewMorePetsText,
		},
	},
	globalConfig,
	...context
}) => {
	const [showAllPets, setShowAllPets] = useState<boolean>(false)
	const {locale: pageLocale = ''} = useRouter()

	const {
		isLoading,
		data: pets = [],
		isError: petApiError,
	} = useQuery<Pet[], Error>({
		queryKey: ['pets'],
		queryFn: () => fetchPetProfiles(pageLocale),
		staleTime: Infinity,
		cacheTime: 15 * (60 * 1000),
	})
	const {
		isLoading: isLoadingReminders,
		data: remindersData,
		isError: reminderApiError,
	} = useQuery<Reminder[], Error>({
		queryKey: ['reminders'],
		queryFn: () => fetchPetReminders(pageLocale),
		staleTime: Infinity,
		cacheTime: 15 * (60 * 1000),
	})

	const findReminder = (petId: string): Reminder[] | undefined =>
		remindersData?.filter((i) => Array(i).find((r) => r.pet?.id === petId))

	if (isLoading) {
		return (
			<div className="container-wide md:min-h-[400px]">
				<div className="my-4 flex flex-col space-y-4 lg:flex-row lg:space-x-4 lg:space-y-0">
					{Array(3)
						.fill(1)
						.map((_, idx) => (
							<div
								className="relative flex shrink animate-pulse flex-col rounded border border-light-grey bg-white p-8 md:basis-1/3"
								// eslint-disable-next-line react/no-array-index-key -- No other choice
								key={`loading-pet-card-${idx}`}
							>
								<div className="flex flex-row items-center gap-8">
									<div className="mb-4 h-16 w-16 animate-pulse rounded-full bg-gray-200" />
									<div className="mb-4 h-6 w-24 animate-pulse rounded bg-gray-200" />
								</div>
								<div className="mb-4 h-10 w-2/3 animate-pulse rounded bg-gray-200" />
								<div className="h-14 w-full animate-pulse rounded bg-gray-200" />
								<span
									aria-busy="true"
									aria-live="polite"
									className="sr-only"
									role="alert"
								>
									Loading...
								</span>
							</div>
						))}
				</div>
			</div>
		)
	}

	if (petApiError || reminderApiError) {
		return (
			<div className="container-wide">
				<Alert
					message={globalConfig.elements.petApiError}
					type="error"
				/>
			</div>
		)
	}

	const getPetCards = (): JSX.Element[] => {
		const petData = [...pets]
		const petCards = petData.map((pet) => (
			<>
				{profilePetCardSnippetParent[0] ? (
					<PetProfileCardSnippetBlock
						block={profilePetCardSnippetParent[0]}
						globalConfig={globalConfig}
						isLoading={isLoadingReminders}
						key={pet.id}
						petData={pet}
						remindersData={findReminder(pet.id) ?? []}
						{...context}
					/>
				) : null}
			</>
		))

		return showAllPets ? petCards : petCards.slice(0, 3)
	}

	return (
		<div className="container-wide md:min-h-[400px]">
			{pets.length > 0 ? (
				<>
					<div className="grid grid-cols-1 gap-4 lg:grid-cols-3">
						{getPetCards()}
					</div>
					{viewMorePetsText ? (
						<div className="my-4 text-center">
							<Button
								className={
									showAllPets || pets.length <= 3
										? 'hidden'
										: 'block'
								}
								onClick={() => {
									setShowAllPets(true)
								}}
								type="button"
								variant="pet_primary_green"
							>
								{viewMorePetsText}
							</Button>
						</div>
					) : null}
				</>
			) : (
				<>
					{createPetCtaCardParent[0] ? (
						<ModuleCreatePetCtaBlockBlock
							block={createPetCtaCardParent[0]}
							globalConfig={globalConfig}
							{...context}
						/>
					) : null}
				</>
			)}
		</div>
	)
}
