import {Button, Icon as CIcon} from '@elanco/component-library-v2'
import {useRouter} from 'next/router'
import Image from 'next/future/image'
import type {Elements, IContentItem} from '@kontent-ai/delivery-sdk'
import {groupReminders} from '@/utils/YPAY/reminders'
import type {Reminder} from '@/_new-code/services/reminders/models'
import type {CtaButtonContentItem} from '@/_new-code/products/flexible-web-toolkit/blocks/cta-button'
import {loadImageFromKontentAI} from '@/imageLoader'
import type {IconContentItem} from '@/_new-code/products/flexible-web-toolkit/blocks/icon'
import type {ExtendedBlock} from '@/_new-code/services/kontent-ai/types'
import {addLocalePrefix} from '@/_new-code/utilities/add-locale-prefix'
import {RichTextRenderer} from '@/_new-code/products/flexible-web-toolkit/components/rich-text-renderer'
import type {Pet} from '@/_new-code/models/consumer-engagement-models'

export type PetProfileCardSnippetContentItem = IContentItem<{
	doseDayLabel: Elements.TextElement
	reminderIcon: Elements.LinkedItemsElement<IconContentItem>
	setupRemindersText: Elements.RichTextElement
	viewPetProfileCta: Elements.LinkedItemsElement<CtaButtonContentItem>
	addReminderCta: Elements.LinkedItemsElement<CtaButtonContentItem>
	viewMorePetsText: Elements.TextElement
}>

export const PetProfileCardSnippetBlock: ExtendedBlock<
	PetProfileCardSnippetContentItem,
	{petData: Pet; remindersData: Reminder[]; isLoading: boolean}
> = ({
	block: {
		elements: {
			doseDayLabel,
			reminderIcon,
			setupRemindersText,
			viewPetProfileCta,
			addReminderCta,
		},
	},
	petData,
	remindersData,
	isLoading,
	globalConfig,
	...context
}) => {
	const router = useRouter()
	const {locale: pageLocale = ''} = router

	const {name, id, imageReference, petSpecies} = petData

	const filteredReminders = [...remindersData].slice(0, 3)
	const groupedMedicationsArray = groupReminders(filteredReminders)

	const icon = globalConfig.elements.petIcons.find(
		(i) => i.system.id === imageReference
	)

	const defaultIcon =
		petSpecies === 'dog'
			? globalConfig.elements.defaultDogIcon
			: globalConfig.elements.defaultCatIcon

	const handleViewPet = (): void => {
		if (!viewPetProfileCta[0]?.elements.url) return
		const pathname = viewPetProfileCta[0].elements.url
		void router.push(
			{pathname: addLocalePrefix(pathname, pageLocale), query: {id}},
			undefined
		)
	}

	const handleAddReminder = (): void => {
		void router.replace(
			{
				pathname: addLocalePrefix(pageLocale, router.asPath),
				query: {reminder: id},
			},
			undefined,
			{shallow: true}
		)
	}

	return (
		<div className="relative flex shrink flex-col rounded border border-light-grey bg-white p-8 md:basis-1/3">
			<div className="mb-4 flex items-center space-x-8">
				<Image
					alt={
						icon?.elements.image[0]?.description ||
						defaultIcon[0]?.elements.image[0]?.description ||
						''
					}
					className="h-16 w-16 rounded-full border-2 border-pet-green"
					height={64}
					loader={loadImageFromKontentAI}
					src={
						icon?.elements.image[0]?.url ||
						defaultIcon[0]?.elements.image?.[0]?.url ||
						''
					}
					width={64}
				/>
				<h3 className="text-xl uppercase leading-6 text-midnight-blue">
					{name}
				</h3>
			</div>
			{!isLoading && groupedMedicationsArray.length > 0 ? (
				<>
					<ul className="mb-4">
						{groupedMedicationsArray.map((r) => (
							<li className="mb-2 flex items-center" key={r.id}>
								{reminderIcon[0]?.elements.icon[0] ? (
									<CIcon noBackground noBorder size="none">
										<Image
											alt={
												reminderIcon[0].elements.icon[0]
													.description ?? ''
											}
											className="h-4 w-4"
											height={16}
											loader={loadImageFromKontentAI}
											src={
												reminderIcon[0].elements.icon[0]
													.url
											}
											width={16}
										/>
									</CIcon>
								) : null}
								<span className="ml-4 w-5/6 italic">
									<p className="text-midnight-blue">
										{r.marketingBrand?.name}
									</p>
									<span className="font-bold text-[#666]">
										{doseDayLabel}:
										<span className="font-normal not-italic">
											&nbsp;{r.recurringDates?.[0]}
										</span>
									</span>
								</span>
							</li>
						))}
					</ul>
					<div className="mt-auto">
						<Button
							className="w-full"
							onClick={handleViewPet}
							type="button"
							variant="pet_primary_green"
						>
							{viewPetProfileCta[0]?.elements.text}
						</Button>
					</div>
				</>
			) : (
				<>
					<RichTextRenderer
						className="mb-4 text-midnight-blue"
						element={setupRemindersText}
						globalConfig={globalConfig}
						{...context}
					/>
					<div className="mt-auto inline-flex flex-col space-y-2">
						<button
							className="btn inline-flex w-full cursor-pointer items-center justify-center overflow-hidden rounded-sm border-2 border-green-600 bg-white px-4 py-3 text-center align-middle font-bold text-green-600 transition-colors duration-200 hover:bg-green-600 hover:text-white"
							onClick={handleAddReminder}
							type="button"
						>
							{addReminderCta[0]?.elements.text}
						</button>
						<Button
							className="w-full"
							onClick={handleViewPet}
							type="button"
							variant="pet_primary_green"
						>
							{viewPetProfileCta[0]?.elements.text}
						</Button>
					</div>
				</>
			)}
		</div>
	)
}
