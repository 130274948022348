import {useInfiniteQuery} from 'react-query'
import {useRouter} from 'next/router'
import {Button} from '@elanco/component-library-v2'
import {useState} from 'react'
import type {Elements, IContentItem} from '@kontent-ai/delivery-sdk'
import type {ExtendedBlock, Tersed} from '@/_new-code/services/kontent-ai/types'
import type {DistributorCardContentItem} from '@/_new-code/products/flexible-web-toolkit/blocks/distributor-card'
import {fetchListerItems} from '@/_new-code/services/lister-items/client'
import {SkeletonLoader} from '@/_new-code/products/flexible-web-toolkit/components/skeleton-loader'
import {spacing} from '../../styles'
import type {ListerItemsQueryKey} from '../module-coupon-lister/types'

export type DistributorCardListerContentItem = IContentItem<{
	initialItemsNumber: Elements.NumberElement
	subsequentItemsNumber: Elements.NumberElement
	buttonText: Elements.TextElement
	paginationString: Elements.TextElement
	snippetSpacingSpacing: Elements.MultipleChoiceElement
}>

export const DistributorCardListerBlock: ExtendedBlock<
	DistributorCardListerContentItem,
	{
		block: {
			elements: {directoryItems?: Tersed<DistributorCardContentItem>[]}
		}
		totalItemCount?: number
	}
> = ({
	block: {
		elements: {
			buttonText,
			initialItemsNumber,
			subsequentItemsNumber,
			paginationString,
			snippetSpacingSpacing,
		},
	},
	BlockMapper,
	...context
}) => {
	const {locale = ''} = useRouter()
	const [pageNumber, setPageNumber] = useState(0)
	const componentSpacing =
		// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- not unnecessary, snippetSpacingSpacing may be null
		spacing[snippetSpacingSpacing[0]?.codename as keyof typeof spacing] ||
		spacing.none

	const initialItemsCount = initialItemsNumber ?? 6
	const subsequentItemsCount = subsequentItemsNumber ?? 6

	const {isLoading, isFetching, data} = useInfiniteQuery({
		queryKey: [
			'distributor_card',
			{
				codename: 'distributor_card',
				locale,
				orderBy: 'order_alphabetically_by_title',
				skip: pageNumber * subsequentItemsCount,
				limit:
					pageNumber === 0 ? initialItemsCount : subsequentItemsCount,
			},
		] as ListerItemsQueryKey,
		queryFn: (opts) =>
			fetchListerItems<Tersed<DistributorCardContentItem>>(opts),
	})
	const totalCount = data?.pages[0]?.pagination.totalCount ?? 0

	const getDisplayedItemCount = (): number => {
		const displayedCount =
			pageNumber === 0
				? initialItemsCount
				: initialItemsCount + pageNumber * subsequentItemsCount

		return Math.min(displayedCount, totalCount)
	}

	const paginationText = paginationString
		.replace('{{X}}', getDisplayedItemCount().toString())
		.replace('{{Y}}', totalCount.toString())

	const getHasNextPage = (): boolean => getDisplayedItemCount() < totalCount

	return (
		<div
			className={`mx-auto ${componentSpacing}`}
			style={{maxWidth: '1200px'}}
		>
			<p className="mb-4 text-center font-bold sm:text-left">
				{paginationText}
			</p>
			{isLoading ? <SkeletonLoader amount={initialItemsCount} /> : null}
			<div className="flex flex-col">
				{data?.pages.map((page) =>
					page.items.map((item) => (
						<div className="w-full" key={item.system.id}>
							<BlockMapper blocks={[item]} {...context} />
						</div>
					))
				)}
			</div>
			{isFetching ? (
				<SkeletonLoader amount={subsequentItemsCount} />
			) : null}
			{buttonText && getHasNextPage() ? (
				<div className="my-10 w-full text-center">
					<Button
						onClick={() => {
							setPageNumber((prev) => prev + 1)
						}}
						type="button"
					>
						{buttonText}
					</Button>
				</div>
			) : null}
		</div>
	)
}
